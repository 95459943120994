<mat-card>
  <mat-card-title-group>
    <mat-card-title>{{customer.name}}</mat-card-title>
    <mat-card-subtitle>CVR: {{customer.reg_no}}</mat-card-subtitle>
    <div fxLayoutAlign="end">
      <button mat-mini-fab color="primary" (click)="editCustomer()"><mat-icon>edit</mat-icon></button>
    </div>
  </mat-card-title-group>
  <mat-card-content>
    <div fxFill="row" fxLayoutGap="10px">
      <div fxFlex="33">
        <p *ngIf="customer.care_of">{{customer.care_of}}</p>
        <p>
          {{customer.street}}<br/>
          {{customer.zip}} {{customer.city}}<br/>
          {{customer.country}}
        </p>
        <p>
          <span class="mat-body-2">{{customer.contact}}</span><br/>
          <a class="plain" [href]="'mailto:' + customer.email" matTooltip="Skriv e-mail til {{customer.email}}}">{{customer.email}}</a><br/>
          <a class="plain" [href]="'tel:' + customer.phone" matTooltip="Ring til {{customer.phone}}">{{customer.phone}}</a>
        </p>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="33">
        <dl>
          <dt>Sprog:</dt>
          <dd><b>{{customer.settings.board?.language}}</b></dd>

          <dt>Ansøgersprog:</dt>
          <dd><b>{{customer.settings.languages}}</b></dd>

          <dt>Tidszone:</dt>
          <dd><b>{{customer.settings.time_zone}}</b></dd>
        </dl>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex="30">
        <p>
          <span class="mat-body-2">Kunde tag: </span><br/>
          <b>{{customer.settings?.tag}}</b>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="mt-3" *ngIf="!isActivated">
  <mat-card-title-group>
    <mat-card-title>Prøvetilstand!</mat-card-title>
  </mat-card-title-group>
  <mat-card-content>
    <p>Fonden har ikke aktiveret GrantOne.<p>
    <p>Når GrantOne aktiveres slettes alle data fra prøveperioden, dvs. alle ansøgninger, møder, bevillinger, udbetalinger etc.</p>
    <p>Det er derfor vigtigt, at GrantOne aktiveres inden fonden begynder at modtage og behandle ansøgninger.</p>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout fxLayoutAlign="flex-end center">
      <button
        mat-raised-button
        color="primary"
        (click)="doActivate()"
      ><mat-icon>verified</mat-icon> Aktiver!</button>
    </div>
  </mat-card-actions>
</mat-card>
<mat-divider class="my-3"></mat-divider>
<div class="statistics my-3" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center">
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Projects"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Applied"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Granted"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Paid"
  ></app-customer-stats>
</div>
<div class="statistics" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center">
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Profiles"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Messages"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Attachments"
  ></app-customer-stats>
  <app-customer-stats
    fxFlex="25"
    [subdomain]="customer.subdomain"
    [type]="UsageStatistics.Assets"
  ></app-customer-stats>
</div>
<mat-divider class="my-3"></mat-divider>
<mat-card>
  <mat-card-title>Kategorier</mat-card-title>
  <mat-card-content fxLayout="row wrap" fxLayoutGap="3em">
    <ng-container *ngFor="let category of categories">
      <div fxFlex="30" class="category" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="2em" fxLayoutAlign="start baseline">
          <div class="category-initials mt-2" [ngStyle]="{'background-color': category?.color}">
            <b>{{category?.initials}}</b>
          </div>
          <h3 class="m-0">{{category?.name}}</h3>
        </div>
        <button mat-icon-button (click)="editCategory(category)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
<mat-divider class="my-3"></mat-divider>
