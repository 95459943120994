import {DisplayField} from "./display";
import {BulkAction} from './bulk-action';
import { OverviewXlsxColumn } from './overview-xlsx-column';

export interface Settings {
  accounting: AccountingSettings;
  approval_confirmation: ApprovalConfirmationSettings;
  archive: ArchiveSettings;
  authentication: AuthenticationSettings;
  automation: AutomationSettings;
  bulk_actions: BulkActionSettings;
  'case-workers': CaseWorkersSettings;
  comments: CommentsSettings;
  criteria: CriteriaSettings;
  'custom-value'?: CustomValueSettings;
  extra_columns?: ExtraColumnsSettings;
  dialog?: DialogSettings;
  forecast: ForecastSettings;
  gdpr?: GdprSettings;
  import?: ImportSettings;
  applicant: ApplicantSettings;
  payments?: PaymentsSettings;
  'payment-receipts': PaymentReceiptsSettings;
  profile: ProfileSettings;
  scoring: ScoringSettings;
  security?: SecuritySettings;
  sms?: SmsSettings;
  smtp?: SmtpSettings;
  support?: SupportSettings;
  recommendations: RecommendationsSettings;
  materialized_view: MaterializedViewSettings;
  'paper-refusal': RefusalSettings;
  languages: string[];
  time_zone: string;
  board: {
    version?: string;
    language: string;
  };
  votes: VotesSettings;
  overview_xlsx: OverviewXlsxSettings;
  siblings: SiblingsSettings[];
  transfer?: TransferSettings;
  //portal notifications cleanup
  notifications: NotificationsSettings,
  [key: string]: any;
  styling?: StylingSettings
  'split-grants'? : SplitGrantsSettings;
  tag: string;
  employee_no: EmployeeNumberSettings,
  'tax-report'?: TaxSettings;
  ai?: AISettings,
  warnings: WarningsSettings;
}

export interface OverviewXlsxSettings {
  columns: OverviewXlsxColumn[];
}

export interface TransferSettings {
  case_documents?: boolean;
  messages?: boolean;
  recommendation?: boolean;
}

export interface StylingSettings {
  primaryColor: string;
  accentColor: string;
  warnColor: string;
  headerFontFamily: string;
  bodyFontFamily: string;
  backgroundColor: string;
  textColor: string;
  headerTextColor: string;
  matCardColor: string;
  headerWeight: number;
  bodyWeight: number;
}

export interface AccountingSettings {
  payment_credit_account: string;
  payment_debit_account: string;
  provision_account_no: string;
  account_no: string;
  intermediate_account?: string;
}

export interface ApprovalConfirmationSettings {
  list_projects: boolean;
  automatic: {
    enabled: boolean;
    grace: number;
  }
}

export interface ArchiveSettings {
  receipts: boolean;
  rejections: boolean;
  approvals: boolean;
  email?: string;
}

export interface AuthenticationSettings {
  modes: ('mitid' | 'nemid' | 'codefile' | 'tfa' | 'sso')[];
}

export interface AutomationSettings {
  triggers?: string[];
  actions?: string[];
}

export type BulkActionOption = {
  buffer: number
}

export type BulkActionOptions = Partial<Record<BulkAction, BulkActionOption>>;

export interface BulkActionSettings {
  actions?: BulkAction[],
  action_options?: BulkActionOptions
}

export interface CaseWorkersSettings {
  remove_delay: number;
  daily_reminder: boolean;
}

export interface CommentsSettings {
  shared: boolean;
}

export interface CriteriaSettings {
  individual: boolean;
  pct_precision?: number;
}

export interface CustomValueSettings {
  decimals: number;
}

export const dialogThreadTypes = ['applicant', 'expert', 'none'] as const;
export type DialogThreadType = typeof dialogThreadTypes[number];

export type DialogSettings = {
  shared_reader: boolean,
  internal_senders: string[],
  thread_type: DialogThreadType
}

export type ForecastDimension = {
  code: string,
  name: string
}

export type ForecastSettings = {
  no_exceeding_available: boolean;
  least_one_source: boolean;
  multiple_sources: boolean;
  allow_change_after_approval: boolean;
  dimensions: {
    [K in number]: ForecastDimension[]
  }
}

export interface ExtraColumnsSettings {
  fields: DisplayField[];
}

export interface GdprSettings {
  refused_and_moved: number;
  old_approved: number;
  newly_approved: number;
  newly_refused: number;
  dangling: number;
  total_history: boolean;
  dead_drafts: number;
  withdrawn: number;
  inactive_profiles: number;
  attachment_keep_on_clean: boolean;
  message_keep_on_clean: boolean;
  report_keep_on_clean: boolean;
  mail?: string[];
  purge_enabled: boolean;
  purge_dangling: number;
  purge_refused: number;
  purge_approved: number;
  purge_log: number;
  cleaner_disabled: boolean;
}

export interface NotificationsSettings {
  cleanup: {
    noticed: number,
    unnoticed: number
  }
}

export const applicantDigests = ['company', 'employee_no', ''] as const;
export type ApplicantSettings = {
  digest: typeof applicantDigests[number];
  force_digest: boolean;
  production_unit_on_top: boolean;
}

export interface EmployeeNumberSettings {
  employeeNoRegex: string;
}

export interface PaymentsSettings {
  notify_accounting: string;
  'file-format': 'NordeaCsv' | 'BusinessOnlineCsv' | 'NykreditCsv' | 'SydbankCsv' | 'PaymentsXlsx',
  notify_applicant: boolean;
  use_p_no: boolean;
  frequency: number[];
  skip_weeks?: number[];
}

export interface PaymentReceiptsSettings {
  receipts_amount_check: boolean;
}

export interface ProfileSettings {
  account_alert_on_payment_request: boolean;
  logins: ('email' | 'nemid' | 'mitid')[];
  signups: ('email' | 'nemid' | 'mitid')[];
  force: boolean;
  confirmation: boolean;
  allow_copy_of_rejection: boolean;
}

export type RecommendationStyle = 'standard' | 'ap';
export type RecommendationCompactHistory = 'digest' | 'company_registration_no';
export type RecommendationsSettings = {
  warning_multiple_applications: boolean;
  hide_priority: boolean;
  style: 'standard' | 'ap';
  attachment_scale: number;
  show_private_name: boolean;
  show_private_address: boolean;
  compact_history: RecommendationCompactHistory;
  priorities: string[];
  exceeding_grant_warning: boolean;
}

export interface RefusalSettings {
  template: string;
}

export interface ScoringSettings {
  range?: {
    label: string,
    value: number
  }[];
}

export interface SecuritySettings {
  mail?: string[];
  skip_ip_check: boolean;
}

export interface SmsSettings {
  all_messages: boolean;
  approval: boolean;
  refusal: boolean;
  sender: string;
}

export interface SplitGrantsSettings {
  lock_before_notify: boolean;
}

export interface SiblingsSettings {
  subdomain: string,
  share_history: boolean,
  share_profiles: boolean
}

export interface SmtpSettings {
  disable_deliveries: boolean;
  from?: string | null;
  reply_to?: string | null;
}

export interface SupportSettings {
  email: string;
}

export interface TaxSettings {
  report_on_past_year: boolean;
}

export type MaterializedViewSettings = {
  schedule: number
}

export interface VotesSettings {
  show_details: boolean;
}

export interface ImportSettings {
  show_import_project_statuses: boolean;
}

export const promptTypes = ['recommendation', 'rating', 'chat'] as const;
export type PromptType = typeof promptTypes[number];
export type AISettings = {
  allow_gdpr_unsafe_files: boolean,
  prompt_types: PromptType[],
}

export type WarningsSettings = {
  exceeding_grant_warning: boolean
}
