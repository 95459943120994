import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";

import {DataService, CustomersService, GrantComponentsService} from "../../core/services";
import {Administrator, Country, GrantComponent, Language, NewUser, Onboarding, TimeZone, User} from '../../core/models';
import {listAnimation} from "../../core/animations/list-animation";
import {validateForm} from "../../core/helpers/validate-form.helper";
import {defaultSettings} from "../../core/models/default-settings";
import {AdministratorService} from "../../core/services/administrator.service";

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss'],
  animations: [listAnimation]
})
export class NewCustomerComponent implements OnInit {

  countries: Country[] = [];
  languages: Language[] = [];
  timeZones: TimeZone[] = [];

  searchString: string = '';

  form: FormGroup = this.fb.group({
    reg_no: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]+$')]],
    subdomain: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60), Validators.pattern('^[a-z0-9-_]+$')]],
    name: ['', [Validators.required, Validators.maxLength(100)]],
    contact: ['', [Validators.required, Validators.maxLength(100)]],
    email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
    phone: ['', [Validators.required, Validators.maxLength(16), Validators.pattern('^(\\+|00)?[0-9 ]+$')]],
    care_of: ['', Validators.maxLength(100)],
    street: ['', [Validators.required, Validators.maxLength(100)]],
    zip: ['', [Validators.required, Validators.maxLength(20)]],
    city: ['', [Validators.required, Validators.maxLength(100)]],
    region: ['', Validators.maxLength(100)],
    country: ['dk', Validators.required],
    bank: ['generic', Validators.required],
    custody_no: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern('^[0-9-]+$')]],
    currency: ['dkk', Validators.required],
    nem_konto: [true],
    accounting: [false],
    capital: [0, Validators.required],
    board_language: ['da', Validators.required],
    base_languages: [['da'], Validators.required],
    time_zone: ['Europe/Copenhagen', Validators.required]
  });
  categoriesForm: FormGroup;
  allAdminUsers: Administrator[] = [];
  selectedAdminUsers: NewUser[] = [];

  allComponents: GrantComponent[] = [];
  filteredComponents: GrantComponent[] = [];
  selectedComponents: number[] = [];

  constructor(private router: Router,
              private customersService: CustomersService,
              private componentsService: GrantComponentsService,
              private adminService: AdministratorService,
              private dataService: DataService,
              private fb: FormBuilder) {
    this.categoriesForm = this.fb.group({
      categories: this.fb.array([this.buildCategory()])
    });
  }

  ngOnInit(): void {
    this.dataService.getCountries()
      .subscribe(countries => this.countries = countries);
    this.dataService.getLanguages()
      .subscribe(languages => this.languages = languages);
    this.dataService.getTimeZones()
      .subscribe(timeZones => this.timeZones = timeZones);
    this.componentsService.getComponents()
      .subscribe(components => {
        this.allComponents = components;
        this.allComponents.sort((a,b) => a.name.localeCompare(b.name))
        this.filteredComponents = [...this.allComponents];
      });
    this.getUsers();
  }

  getUsers(): void {
    this.adminService.getAll()
      .subscribe(users => {
        this.allAdminUsers = users;
      })
  }

  doLookupCvr() {
    const regNo = this.form.get('reg_no');
    if (!regNo || regNo.invalid) {
      return;
    }
    this.form.disable();
    this.customersService.lookupCompany(regNo?.value).subscribe((data) => {
      this.form.enable();
      if (data.company === undefined) {
        this.form.controls['reg_no'].setErrors({ unknown: true });
        return;
      }
      let value = {
        name: data.company,
        street: data.street,
        city: data.city,
        zip: data.zip,
        country: data.country,
        phone: data.phone,
        email: data.email,
      };
      // quick and dirty domain suggestion
      if (!this.form.controls['subdomain']?.value) {
        let subdomain = this.toDomainSuggestion(data.company);
        value = Object.assign({}, value, { subdomain: subdomain });
      }
      this.form.patchValue(value);
      this.doCheckSubdomain();
      this.form.get('subdomain')?.markAsTouched();
    });
  }

  doCheckSubdomain() {
    const subdomain = this.form.get('subdomain');
    if (!subdomain || subdomain.invalid) {
      return;
    }
    this.form.disable();
    this.customersService.checkSubdomain(subdomain?.value).subscribe((data) => {
      this.form.enable();
      if (data.status === 'failure') {
        this.form.controls['subdomain'].setErrors({ taken: true });
        return;
      }
    });
  }

  doAppendCategory() {
    const categories = this.categoriesForm.get('categories') as FormArray;
    categories.push(this.buildCategory());
  }

  doRemoveCategory(idx: number) {
    const categories = this.categoriesForm.get('categories') as FormArray;
    categories.removeAt(idx);
  }

  get categories(): AbstractControl[] {
    const categories = this.categoriesForm.get('categories') as FormArray;
    return categories.controls;
  }

  doToggleAdminUser(admin: Administrator, checked: boolean): void {
    checked ? this.addBoardUser(admin) : this.removeBoardUser(admin);
  }

  addBoardUser(admin: Administrator): void {
    const initials = this.getInitials(admin);
    const boardUser = {
      name: admin.name,
      initials: initials,
      email: admin.email,
      cpr: admin.cpr,
      provider: 'mitid'
    }

    this.selectedAdminUsers.push(boardUser);
  }

  removeBoardUser(admin: Administrator): void {
    const idx = this.selectedAdminUsers.findIndex(a => a.email === admin.email);
    this.selectedAdminUsers.splice(idx, 1);
  }

  isAdminUserSelected(admin: Administrator): boolean {
    return this.selectedAdminUsers.filter(user => user.email === admin.email).length > 0;
  }

  getInitials(admin: Administrator): string {
    if (admin.name.indexOf(' ') === -1) {
      return admin.name.charAt(0).toUpperCase();
    } else {
      const names = admin.name.split(' ');
      return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
    }
  }

  selectComponent(componentId: number, checked: boolean) {
    if (checked) {
      this.selectedComponents.push(componentId);
    } else {
      const index = this.selectedComponents.indexOf(componentId);
      this.selectedComponents.splice(index, 1);
    }
  }
  toggleAll(): void {
    if (this.selectedComponents.length === this.allComponents.length) {
      this.selectedComponents = [];
    } else {
      this.selectedComponents = this.allComponents.map(c => c.id).sort();
    }
  }
  showChooseAll(): boolean {
    return this.selectedComponents.length !== this.allComponents.length;
  }

  doCreate(): void {
    // check forms are valid
    if (this.form.invalid || this.categoriesForm.invalid) {
      validateForm(this.form);
      validateForm(this.categoriesForm);
      return;
    }
    // ok, start working
    this.form.disable();
    const value = this.form.value;
    const data: Onboarding = {
      customer: {
        subdomain: value.subdomain,
        reg_no: value.reg_no,
        name: value.name,
        contact: value.contact,
        email: value.email,
        email_confirmation: value.email,
        phone: value.phone,
        address_attributes: {
          care_of: value.care_of,
          street: value.street,
          zip: value.zip,
          city: value.city,
          region: value.region,
          country: value.country
        },
        wealth_manager_attributes: {
          bank: value.bank,
          custody_no: value.custody_no,
          nem_konto: value.nem_konto,
          accounting: value.accounting,
          capital: value.capital
        },
        settings: defaultSettings
      },
      categories: [],
      users: this.selectedAdminUsers
    };
    data.customer.settings.languages = value.base_languages;
    data.customer.settings.board.language = value.board_language;
    data.customer.settings.time_zone = value.time_zone;

    const categories = this.categoriesForm.get('categories') as FormArray;
    data.categories = categories.value;
    this.customersService.create(data)
      .subscribe(customer => {
        this.router.navigate(['/customers', customer.id]);
        this.componentsService.saveComponents(this.selectedComponents, customer.id)
          .subscribe(customer => {
            this.customersService.customerChange.next();
            //all done
          })
      });
  }

  buildCategory(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      initials: ['', [Validators.maxLength(3), Validators.required]],
      tax_code_id: 1,
      payment_type: 'installments'
    });
  }

  toDomainSuggestion(name: string): string {
    return name.toLowerCase()
      .replace(/ (a\/s|aps)$/, '')
      .replace(/æ/g, 'ae')
      .replace(/ø/g, 'oe')
      .replace(/å/g, 'aa')
      .replace(/\//g, '-')
      .replace(/\s+/g, '-');
  }

  search(): void {
    if (this.searchString === '') {
      this.filteredComponents = [...this.allComponents]
      return;
    };
    this.filteredComponents = this.allComponents.filter(component => {
      return component.name.toLocaleLowerCase().includes(this.searchString.toLowerCase());
    });
  }
}
